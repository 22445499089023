.horizontal-list > .row {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
}
.horizontal-list > .row > .col-xs-4 {
    flex: 0 0 auto;
}

::-webkit-scrollbar {
    display: none;
}

.slick-center {
    -webkit-transform: scale(1.4);
    -moz-transform: scale(1.4);
    transform: scale(1.4);
    /* -webkit-transform: scale(1.25);
    -moz-transform: scale(1.25);
    transform: scale(1.25); */
    margin-top: -10px;
}

.ellipsis-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    text-overflow: ellipsis;
    /* width: calc(100%); */
}